// App.js
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import myImage from './home.jpg';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Footer from './components/Footer';

function App() {
  const [scrollClass, setScrollClass] = useState('top');

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 10) {
        setScrollClass('scrolled');
      } else {
        setScrollClass('top');
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div className="App">
      <Header scrollClass={scrollClass} />
      <Home myImage={myImage} />
      <About />
      <Footer />
    </div>
  );
}

export default App;
