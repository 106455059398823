import React from 'react';

const Footer = () => (
  <footer id="contact" className="bg-dark text-white py-3">
    <div className="container">
      <h3>Contact Us</h3>
      <p>Address: College Station, TX</p>
      <small>&copy; Copyright 2023, Dutchnpay Inc.</small>
    </div>
  </footer>
);

export default Footer;
