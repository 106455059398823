import React, { useState } from 'react';
import axios from 'axios';

const About = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    
    axios.post('http://ec2-44-211-254-178.compute-1.amazonaws.com:3000/api/users/joinwaitlist', { email })
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
    });
    
    alert(`Email ${email} has been added to the waitlist.`);
    setEmail('');
  };
  
  return (
  <section id="about" className="py-5">
    <div className="container">
      <h2>About Us</h2>
        <p>Hi we are Dutchnpay.</p>
        <div className="mt-4">
        <h4 className="text-center">Seems Exciting Right?</h4>
        <h4 className="text-center">Join Our Waitlist To Get the Easliest Access</h4>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6 mx-auto">
              <div className="input-group justify-content-center">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className="btn btn-primary">
                  Join Waitlist
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    </section>
    );
};

export default About;
