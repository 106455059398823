import React from 'react';
import ChangingText from './ChangingText';

const Home = ({ myImage }) => (
  <section id="home" className="d-flex min-vh-100">
    <div className="container my-auto">
      <div className="row">
        <div className="col-md-6">
          <h1 className="display-3">Introducing</h1>
          <h1 className="display-1 text-white">DutchnPay!</h1>
          <br></br>
          <br></br>
        </div>
        <div className="col-md-6">
          <img src={myImage} className="img-fluid" alt="My Company" />
        </div>
      </div>
    </div>
  </section>
);

export default Home;
